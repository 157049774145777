import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { CrudService } from '@app/global/services/crud.service';
import { DataModels, Permissions } from '@app/global/models/enums';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private sharedVars: SharedVarsService,
    private crud: CrudService
  ) { }

  createUser(data: any) {
    return new Promise((resolve, reject) => {
      this.crud.post('users', data).then((res) => {
        if (res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }

  updateUser(id: number, data: any) {
    return new Promise((resolve, reject) => {
      this.crud.put('users', id, data).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }

  getAllUsers(pageNumber?: number, query?: string, sort?: string) { 
    return new Promise((resolve, reject) => {
      this.crud.list('users', pageNumber, {search: query, ordering: sort }).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  getApprovedUsers() {
    return new Promise((resolve, reject) => {
      this.crud.list('users', 0, {approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  getUserDetail(id: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('users', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getLeaveTypes() {
    return new Promise((resolve, reject) => {
      this.crud.list('leavetypes').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getDepartments() {
    return new Promise((resolve, reject) => {
      this.crud.list('departments').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getDropdownUsers() { 
    return new Promise((resolve, reject) => {
      this.crud.list('user_choices').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  
  getSupervisors() {
    return new Promise((resolve, reject) => {
      this.crud.list('user_choices', 0, {is_supervisor: 1}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * 
   * @param model - model name
   * @param permission - permission name
   * @returns 
   */
  userHasAllPermission(model: DataModels, permission: Permissions) {
    if (this.sharedVars.USER_DETAIL?.is_admin) {
      return true;
    }
    // If not, check if the user has the permission for team or all
    if (
      this.sharedVars.USER_DETAIL &&
      this.sharedVars.USER_DETAIL.perms &&
      this.sharedVars.USER_DETAIL.perms.length
    ) {
      const isAll = this.sharedVars.USER_DETAIL.perms.some((perm) => {
        return perm === `${model}_${permission}_all` || perm === `${model}_all_all`;
      });
      if (isAll) {
        return true;
      }
    }
    return false
  }
  /**
   * 
   * @param model - model name
   * @param permission - permission name
   * @param creatorId - id of the creator of the object
   * @returns 
   */
  userHasPermission(model: DataModels, permission: Permissions, creatorId: number = 0) {
    // First check if the user is the creator of the object
    const isCreator = creatorId === this.sharedVars.USER_DETAIL.id; 
    if (this.sharedVars.USER_DETAIL?.is_admin) {
      return true;
    }
    // If not, check if the user has the permission for team or all
    if (
      this.sharedVars.USER_DETAIL &&
      this.sharedVars.USER_DETAIL.perms &&
      this.sharedVars.USER_DETAIL.perms.length
    ) {
      if(permission === 'create'){
        const isCreate = this.sharedVars.USER_DETAIL.perms.some((perm) => {
          return perm === `${model}_create`;
        });
        if (isCreate) {
          return true;
        }
      }

      const isAll = this.sharedVars.USER_DETAIL.perms.some((perm) => {
        return perm === `${model}_${permission}_all` || perm === `${model}_all_all`;
      });
      if (isAll) {
        return true;
      }

      // For conditions where user is listing/editing team objects
      const isTeam = this.sharedVars.USER_DETAIL.perms.some((perm) => {
        return perm === `${model}_${permission}_team` || perm === `${model}_all_team`;
      });
      if (isTeam) {
        return creatorId ? this.sharedVars.USER_DETAIL.team.includes(creatorId): true;
      }

      // For conditions where user is listing own objects
      const isOwn = this.sharedVars.USER_DETAIL.perms.some((perm) => {
        return perm === `${model}_${permission}_own` || perm === `${model}_all_own`;
      });
      if (isOwn && (!creatorId || isCreator)) {
        return true;
      }
    }
    return false
  }

  getPermissions(id?: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('permissions', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }


  savePermissions(id: number, data: any) {
    return new Promise((resolve, reject) => {
      this.crud.patch('permissions', id, data).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }

  getAllHsnCodes() {
    return new Promise((resolve, reject) => {
      this.crud.get('hsn').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }

  getAllGstRates() {
    return new Promise((resolve, reject) => {
      this.crud.get('gst-rates').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }

  updateLeaveBalance(data: any) {
    return new Promise((resolve, reject) => {
      this.crud.post('leave_balance', data).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }
}
